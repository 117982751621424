<template>
  <div class="reward-activity-limit-block">
    <p class="card-title">活動回饋上限設定</p>
    <BaseElForm ref="formRef" :model="formData" :rules="formRules">
      <BaseElFormItem prop="rewardLimitPerTransaction">
        <div class="flex flex-col">
          <p><span class="text-danger">* </span>單筆回饋上限（設定 0 代表無上限）</p>
          <div class="flex" style="gap: 20px;">
            <span>每筆訂單至多回饋</span>
            <BaseElInput v-model="formData.rewardLimitPerTransaction" style="width: 100px" placeholder="請輸入" />
            <p>
              <span>{{ rewardActivityTypeConfig[rewardType].unit }}</span>
              <span v-if="rewardType === 'CASHBACK'">{{ rewardActivityTypeConfig[rewardType].label }}</span>
            </p>
          </div>
        </div>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { noEmptyRules, isDigitRules } from '@/validation'
import { get } from 'lodash'
import { rewardActivityTypeConfig } from '@/config/rewardActivity'

export default defineComponent({
  name: 'RewardActivityLimitBlock',
  props: ['FormsContext', 'rewardType', 'activityData'],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({
      rewardLimitPerTransaction: null,
    })
    const formRules = {
      rewardLimitPerTransaction: [noEmptyRules(), isDigitRules()],
    }

    const syncData = () => {
      const data = props.activityData
      formData.rewardLimitPerTransaction = data.rewardLimitPerTransaction
    }

    const compactData = computed(() => {
      const data = {
        rewardLimitPerTransaction: get(formData, 'rewardLimitPerTransaction'),
      }
      return { ...data }
    })

    watch(formData, () => {
      props.FormsContext.setFormData('limit', { ...compactData.value })
    })

    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('limit', formRef.value)

      if (get(props.activityData, 'id')) syncData()
    })
    return { formRef, formData, formRules, rewardActivityTypeConfig }
  },
})
</script>

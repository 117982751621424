<template>
  <div class="reward-activity-rules-block">
    <p class="card-title">活動規則設定</p>
    <BaseElForm ref="formRef" :model="formData" :rules="formRules">
      <div class="flex flex-col el-form-item__content">
        <p><span class="text-danger">* </span>回饋條件</p>
        <div class="flex" style="gap: 20px;">
          <span>每消費</span>
          <BaseElFormItem prop="fixedAmount">
            <BaseElInput v-model="formData.fixedAmount" style="width: 100px" placeholder="請輸入" />
          </BaseElFormItem>
          <span>元，可獲得</span>
          <BaseElFormItem prop="fixedPoint">
            <BaseElInput v-model="formData.fixedPoint" style="width: 100px" placeholder="請輸入" />
          </BaseElFormItem>
          <p>
            <span>{{ rewardActivityTypeConfig[rewardType].unit }}</span>
            <span v-if="rewardType === 'CASHBACK'">{{ rewardActivityTypeConfig[rewardType].label }}</span>
          </p>
        </div>
      </div>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, computed, watch } from 'vue'
import { noEmptyRules, isDigitRules } from '@/validation'
import { get } from 'lodash'
import { rewardActivityTypeConfig } from '@/config/rewardActivity'

export default defineComponent({
  name: 'RewardActivityRulesBlock',
  props: ['FormsContext', 'rewardType', 'activityData'],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({
      fixedAmount: null,
      fixedPoint: null,
    })
    const formRules = {
      fixedAmount: [noEmptyRules(), isDigitRules()],
      fixedPoint: [noEmptyRules(), isDigitRules()],
    }

    const syncData = () => {
      //  目前規則只有一種所以先抓第0筆
      const data = props.activityData
      const RewardActivityRules = get(data, 'RewardActivityRules')
      formData.fixedAmount = RewardActivityRules[0].rewardRule.fixedAmount
      formData.fixedPoint = RewardActivityRules[0].rewardRule.fixedPoint
    }

    const compactData = computed(() => {
      //  規則目前只有一種
      const data = {
        rewardActivityRules: [
          {
            conditions: [],
            operator: 'OR',
            rewardRule: {
              type: 'EVERY_FIXED_AMOUNT_GET_FIXED_POINT',
              fixedAmount: get(formData, 'fixedAmount'),
              fixedPoint: get(formData, 'fixedPoint'),
            },
          },
        ],
      }

      if (props.activityData?.id) {
        data.rewardActivityRules[0].id = props.activityData.RewardActivityRules[0].id
      }
      return { ...data }
    })

    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('rules', formRef.value)

      if (get(props.activityData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('rules', { ...compactData.value })
    })

    return { formRef, formData, formRules, rewardActivityTypeConfig }
  },
})
</script>

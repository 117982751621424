<template>
  <div class="reward-activity-info-block">
    <p class="card-title">基本資訊</p>
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="活動名稱" prop="name" required>
        <BaseElInput v-model="formData.name" placeholder="請輸入" />
      </BaseElFormItem>
      <BaseElFormItem label="活動期間" prop="date" required>
        <el-date-picker
          v-model="formData.date"
          editable
          type="datetimerange"
          format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          :default-titmes="['00:00:00', '00:00:00']"
        />
      </BaseElFormItem>
      <BaseElFormItem label="活動敘述" prop="description" required>
        <quillEditor v-model="formData.description" :options="editorOption" />
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { useEditor } from '@/use/editor'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { noEmptyRules } from '@/validation'
import { get } from 'lodash'

export default defineComponent({
  name: 'RewardActivityInfoBlock',
  components: { quillEditor },
  props: ['FormsContext', 'activityData'],
  setup (props) {
    const { editorOption } = useEditor()
    const formRef = ref(null)
    const formData = reactive({
      name: null,
      date: null,
      description: null,
    })
    const formRules = {
      name: [noEmptyRules()],
      date: [noEmptyRules()],
      description: [noEmptyRules()],
    }

    const syncData = () => {
      const data = props.activityData
      formData.name = data.name
      formData.date = [
        data.startAt,
        data.endAt,
      ]
      formData.description = data.description
    }

    const compactData = computed(() => {
      const data = {
        name: get(formData, 'name'),
        description: get(formData, 'description'),
      }
      if (get(formData, 'date')) {
        data.startAt = get(formData, 'date')[0]
        data.endAt = get(formData, 'date')[1]
      }
      return { ...data }
    })

    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('info', formRef.value)

      if (get(props.activityData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('info', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      editorOption,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
